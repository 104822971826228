import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: "/",
        name: "home",
        component: () => import("../views/HomeView.vue"),
    },
    {
        path: "/about",
        name: "about",
        component: () => import("../views/AboutView.vue"),
    },
    {
        path: "/services",
        name: "services",
        component: () => import("../views/ServicesView.vue"),
    },
    {
        path: "/faq",
        name: "faq",
        component: () => import("../views/FAQView.vue"),
    },
    {
        path: "/contact",
        name: "contact",
        component: () => import("../views/ContactView.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
